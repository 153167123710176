//AWS Error strings
export const AWS_CODE_FAILURE_USERNOTVERIFIED = 'UserNotConfirmedException';
export const AWS_CODE_FAILURE_PASSWORDRESETREQUIRED = 'PasswordResetRequiredException';

//strings by the Jeff
export const AUTH_CODE_FAILURE_VERIFY_NOUSERNAMEPROVIDED = 'AUTH_CODE_FAILURE_VERIFY_NOUSERNAMEPROVIDED';
export const AUTH_CODE_FAILURE_VERIFY_INCORRECTCODE = 'AUTH_CODE_FAILURE_VERIFY_INCORRECTCODE';


export const BLOG_USERINFO_CALL_FAIL = 'BLOG_USERINFO_CALL_FAIL';
export const AUTH_STATE_LOGIN_FAIL = 'AUTH_STATE_LOGIN_FAIL';
export const AUTH_STATE_LOGIN_FAIL_PASSWORD_RESET = 'AUTH_STATE_LOGIN_FAIL_PASSWORD_RESET';
export const AUTH_STATE_LOGIN_FAIL_USERNOTVERIFIED = 'AUTH_STATE_LOGIN_FAIL_USERNOTVERIFIED';
export const AUTH_STATE_LOGIN_LOADING = 'AUTH_STATE_LOGIN_LOADING';
export const AUTH_STATE_LOGGOUT_FAIL = 'AUTH_STATE_LOGGOUT_FAIL';
export const AUTH_STATE_LOGGOUT_LOADING = 'AUTH_STATE_LOGGED_OUT';
export const AUTH_STATE_VERIFY_FAIL_CAUSEUNKNOWN = 'AUTH_STATE_VERIFY_FAIL_CAUSEUNKNOWN';
export const AUTH_STATE_VERIFY_FAIL_NOUSERNAME = 'AUTH_STATE_VERIFY_FAIL_NOUSERNAME';
export const AUTH_STATE_VERIFY_FAIL_INVALIDCODE = 'AUTH_STATE_VERIFY_FAIL_INVALIDCODE';
export const AUTH_STATE_VERIFYING = 'AUTH_STATE_VERIFYING';
export const AUTH_STATE_VERIFY_SUCCESS = 'AUTH_STATE_VERIFY_SUCCESS';
export const AUTH_STATE_RESENDINGCODE = 'AUTH_STATE_RESENDINGCODE';
export const AUTH_STATE_RESENDCODE_FAIL_CAUSEUNKNOWN = 'AUTH_STATE_RESENDCODE_FAIL_CAUSEUNKNOWN';
export const AUTH_STATE_RESENDCODE_FAIL_MAX_TRIES = 'AUTH_STATE_RESENDCODE_FAIL_MAX_TRIES';
export const AUTH_STATE_RESENDCODE_SUCCESS = 'AUTH_STATE_RESENDCODE_SUCCESS';

//sign up
export const AUTH_STATE_SIGNUP_IN_PROGRESS = 'AUTH_STATE_SIGNUP_IN_PROGRESS';

//used?
export const AUTH_STATE_LOGGED_IN_NOT_CONFIRMED = 'AUTH_STATE_LOGGED_IN_NOT_CONFIRMED';
export const AUTH_STATE_LOGGING_IN = 'AUTH_STATE_LOGGING_IN';
export const AUTH_STATE_FETCHING_USER_INFO = 'AUTH_STATE_FETCHING_USER_INFO';