import React, {Component} from 'react';

class ErrorPage extends Component{
  render(){
    return (
      <div>
        <div>Error! No page found for this url ):</div>
        <p />
        <div>This portion of the website might still be under construction! ( the dev takes too many starcraft breaks XD)</div>
      </div>
    );
  }
}

export default ErrorPage;